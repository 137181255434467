import TableFooter from "./TableFooter";

const PdfTable = ({
  footerShow,
  columns = [],
  data = [],
  doFooterData,
  quotationFooterData,
  invoiceFooterData,
  totalPages,
  pageIndex,
}) => {
  console.log("totalPages", totalPages);
  console.log("pageIndex", pageIndex);
  return (
    <div className="w-full">
      <section className="page-body rounded-lg col-span-12 bg-white lg:col-span-8 w-full">
        <table className="text-sm text-left text-black w-full max-w-full overflow-x-auto">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              {columns.length &&
                columns.map((column, index) => (
                  <th
                    key={index}
                    scope="col"
                    className={
                      column.className ||
                      "px-6 py-1 w-2 border border-gray-950 text-center"
                    }
                  >
                    {column?.header}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="border-gray-950">
            {data &&
              data.length > 0 &&
              data.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={`bg-white border-l border-r border-gray-950 ${
                    data.length - 1 == rowIndex ? "last-row-on-page" : ""
                  }`}
                >
                  {columns.length &&
                    columns.map((column, colIndex) => (
                      <td
                        key={colIndex}
                        className={
                          column.className ||
                          "px-1 border-r border-gray-950 align-top whitespace-normal break-words overflow-hidden"
                        }
                      >
                        {row[column.field]}
                      </td>
                    ))}
                </tr>
              ))}
            {pageIndex === totalPages - 1 && (
              <TableFooter
                footerShow={footerShow}
                doFooterData={doFooterData}
                quotationFooterData={quotationFooterData}
                invoiceFooterData={invoiceFooterData}
              />
            )}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default PdfTable;
