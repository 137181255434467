import React, { useEffect, useRef, useState } from "react";
import { useHeader } from "../../lib/headers";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Modal from "../Shared/Modal";
import BasicModal from "../Shared/BasicModal";
import UploadImages from "./UploadImages";
import { RxCross2 } from "react-icons/rx";
import Alert from "../Shared/Alert";
import { BsPlusCircleDotted } from "react-icons/bs";
import Swal from "sweetalert2";
import VariantSection from "./VariantSection";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { FadeLoader } from "react-spinners";
import Barcode from "react-jsbarcode";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "./PrintComponent";
import BarcodeSection from "./BarcodeSection";
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }, { direction: "ltr" }],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link"],
    ["clean"],
  ],

  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const ViewProduct = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const headers = useHeader();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [productImage, setProductImage] = useState([]);
  const [productfeatureImage, setProductfeatureImage] = useState(null);
  const [productImageURL, setProductImageURL] = useState(null);
  const [isFeatureImageLoading, setIsFeatureImageLoading] = useState(false);
  const [isProductImageLoading, setIsProductImageLoading] = useState(false);
  const [isReload, setIsReload] = useState(1);
  const [description, setDescription] = useState(null);
  // const [showModal, setShowModal] = React.useState(false);
  const [data, setData] = useState({
    purchase_price: null,
    sell_price: null,
    quantity: null,
    unit: null,
  });

  const componentRef = useRef();

  useEffect(() => {
    setDescription(products?.description);
  }, [products]);

  const fetchData = () => {
    axios
      .get(`${process.env.REACT_APP_ROOT_URL}/api/product/${id}`, headers)
      .then((response) => {
        const data = response.data;
        const images = data?.images;
        setProductImage(images);
        setProducts(data);
        const featureImage = images.find((item) => item.is_featured);
        setProductfeatureImage(featureImage);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [isReload, id]);

  const handleClose = () => {
    // setwidthSize("");
    setShowModal(false);
  };

  const handleProdctImage = async (e, is_featured) => {
    if (is_featured) {
      setIsFeatureImageLoading(true);
      setIsProductImageLoading(false);
    } else {
      setIsFeatureImageLoading(false);
      setIsProductImageLoading(true);
    }

    const data = e.target.files[0];

    const formData = new FormData();
    formData.append("image", data);
    formData.append("product", products?.id);
    formData.append("is_featured", is_featured);
    await axios
      .post(
        `${process.env.REACT_APP_ROOT_URL}/api/product_image/`,
        formData,
        headers
      )
      .then((res) => {
        const data = res?.data;
        if (data?.is_featured) {
          setProductfeatureImage(res?.data);
        } else {
          setProductImage([...productImage, data]);
        }

        setIsFeatureImageLoading(false);
        setIsProductImageLoading(false);
        Alert("Product Feature Image Uploaded", "success");
      })
      .catch((err) => {});

    // setProductImage(event.target.files[0]);
    // setProductImageURL(URL?.createObjectURL(event.target.files[0]));
  };

  const handleProductFeatureImageDelete = async (e, item, is_featured) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_ROOT_URL}/api/product_image/${item?.id}/`,
            headers
          )
          .then((response) => {
            if (is_featured) {
              setProductfeatureImage(null);
            } else {
              const rest = productImage?.filter((i) => i?.id !== item?.id);
              setProductImage(rest);
            }

            Alert("Your file has been deleted", "success");
          })
          .catch((error) => {});
      }
    });
  };
  const handlePrice = (e) => {
    const { name, value } = e?.target;
    const newData = { ...data };
    newData[name] = value;
    setData(newData);
  };
  const handlePriceSubmit = async (e) => {
    e.preventDefault();
    data.description = description;
    await axios
      .patch(
        `${process.env.REACT_APP_ROOT_URL}/api/product/${id}/`,
        data,
        headers
      )
      .then((response) => {
        const data = response.data;

        setProducts(data);
        Alert("Prices Updated ", "success");
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const dataToPrint = {
    name: "John Doe",
    age: 30,
    occupation: "Software Engineer",
    address: "123 Main St, City, State",
  };

  return (
    <div>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          showModal={showModal}
          onClose={handleClose}
        >
          <UploadImages />
        </Modal>
      )}
      {/* <BasicModal showModal={showModal} setShowModal={setShowModal} /> */}
      <div className="mx-auto  max-w-7xl">
        <button
          onClick={() => navigate(-1)}
          className=" border rounded p-2  flex  items-center hover:bg-black hover:text-white "
        >
          <IoIosArrowBack />
          <span className=" ml-2"> Back</span>
        </button>
        <div class="mt-5">
          {/* product name , image  */}
          <div className=" flex items-center">
            <div>
              <div>
                <h3 class="text-2xl font-semibold leading-snug sm:pr-8">
                  Name: {products?.name}
                </h3>
                <div className=" flex items-center space-x-7">
                  <p className="text-sm text-gray-500 ">
                    Brand : {products?.brand_name}
                  </p>

                  <p className="text-sm text-gray-500 ">
                    category : {products?.category_name}
                  </p>
                </div>
              </div>
              <div className=" flex items-center space-x-4 my-5">
                {/*<div class="mt-1 flex text-sm bg-gray-200 px-4 py-2 rounded-full text-gray-800">*/}
                {/*  <p class="text-sm font-semibold  ">Has Atrribute</p>*/}
                {/*  <p class="ml-4 font-semibold border-l border-gray-900 pl-4 text-sm">*/}
                {/*    {products?.has_attribute ? "YES" : "NO"}*/}
                {/*  </p>*/}
                {/*</div>*/}
                <div class="mt-1 flex text-sm bg-gray-200 px-4 py-2 rounded-full text-gray-800">
                  <p class="text-sm font-semibold  ">Has Varient</p>
                  <p class="ml-4 font-semibold border-l border-gray-900 pl-4 text-sm">
                    {products?.has_variant ? "YES" : "NO"}
                  </p>
                </div>
                <div class="mt-1 flex text-sm bg-gray-200 px-4 py-2 rounded-full text-gray-800">
                  <p className="text-sm font-semibold  ">Can be Purchase</p>
                  <p className="ml-4 font-semibold border-l border-gray-900 pl-4 text-sm ">
                    {products?.is_purchase ? "YES" : "NO"}
                  </p>
                </div>
                <div class="mt-1 flex text-sm bg-gray-200 px-4 py-2 rounded-full text-gray-800">
                  <p class="text-sm font-semibold ">Can be Sell</p>
                  <p class="ml-4 font-semibold border-l border-gray-900 pl-4 text-sm ">
                    {products?.is_sell ? "YES" : "NO"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Product name ,Imagr end  */}
          {products?.has_attribute && (
            <div className=" ">
              <h1 className=" text-lg font-bold">Attributes</h1>
              <hr className=" my-3" />
              {products?.attributes?.map((i) => (
                <div class="mt-1 flex text-sm">
                  <p class="text-sm text-gray-500 md:w-60">{i?.attribute}</p>
                  <p class="ml-4 border-l border-gray-200 pl-4 text-sm text-gray-500">
                    {i?.value}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* pusrchase and Sell input start */}
        {!products?.has_variant &&
          (products?.is_purchase || products?.is_sell) && (
            <div className="border border-gay-300 px-4 rounded mt-10">
              <div className=" flex justify-center w-full">
                <div>
                  <BarcodeSection
                    name={products?.name}
                    products={products}
                    ref={componentRef}
                  />
                  <ReactToPrint
                    trigger={() => (
                      <button className=" rounded border px-4 py-2 w-full mt-2 bg-gray-100">
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                </div>

                {/* <Barcode
                  value={products?.barcode}
                  options={{ format: "code128", width: 2, height: 50 }}
                  renderer="svg"
                /> */}
                {/* <div>
                  <PrintComponent data={dataToPrint} ref={componentRef} />
                  <button onClick={handlePrint}>Print this out!</button>
                </div> */}
              </div>
              {/* update sells Price,purschase Price and Quantity start from here */}
              <form onSubmit={handlePriceSubmit}>
                <div className=" mt-6 grid grid-cols-12 gap-4 place-items-center ">
                  {products?.is_purchase && (
                    <div className=" mt-2 mb-2 col-span-3 w-full ">
                      <h1 className=" text-lg font-bold">Purchase Price</h1>

                      <div class=" w-full">
                        <input
                          defaultValue={products?.purchase_price}
                          onChange={handlePrice}
                          type="text"
                          name="purchase_price"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                          required
                        />
                      </div>
                    </div>
                  )}

                  {products?.is_sell && (
                    <div className=" mt-2 mb-2 col-span-3 w-full">
                      <h1 className=" text-lg font-bold">Sell Price</h1>

                      <div class=" w-full">
                        <input
                          defaultValue={products?.sell_price}
                          onChange={handlePrice}
                          type="text"
                          name="sell_price"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                        />
                      </div>
                    </div>
                  )}

                  <div className=" mt-2 mb-2 col-span-3 w-full">
                    <h1 className=" text-lg font-bold">Unit</h1>

                    <div class=" w-full">
                      <input
                        defaultValue={products?.unit}
                        onChange={handlePrice}
                        type="text"
                        name="unit"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                      />
                    </div>
                  </div>

                  <div className=" mt-2 mb-2 col-span-3 w-full">
                    <h1 className=" text-lg font-bold">Available Quantity</h1>

                    <div class=" w-full">
                      <input
                        defaultValue={products?.quantity}
                        onChange={handlePrice}
                        type="text"
                        name="quantity"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                      />
                    </div>
                  </div>
                </div>
                <div className=" w-full h-32 mt-3">
                  <ReactQuill
                    className="h-full"
                    modules={modules}
                    value={description}
                    onChange={setDescription}
                  />
                </div>
                <button
                  type="submit"
                  class="mt-16 mb-4  py-2 px-3  text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                >
                  Submit
                </button>
              </form>
              {/* update sells Price,purschase Price and Quantity end here */}
              <div className=" my-2  grid grid-cols-5 gap-4  ">
                {!products?.has_variant && (
                  <>
                    {/* feature Image Section start from here */}
                    {productfeatureImage ? (
                      <div class=" group  h-40  p-1 md:p-2 relative ">
                        <img
                          alt="gallery"
                          class="ring-2 ring-green-600 block object-cover object-center w-full h-full rounded-lg"
                          src={productfeatureImage?.image}
                        />
                        <p className="absolute left-[22%] top-[50%] block group-hover:bg-gray-300 group-hover:p-1 group-hover:rounded  text-gray-400">
                          Feature Image
                        </p>
                        <button
                          onClick={(e) =>
                            handleProductFeatureImageDelete(
                              e,
                              productfeatureImage,
                              true
                            )
                          }
                          className=" hidden group-hover:block hover:bg-white bg-red-400 hover:text-red-600 text-white absolute top-2 right-2 rounded-full  "
                        >
                          <RxCross2 size={30} className=" p-2" />
                        </button>
                      </div>
                    ) : (
                      <div class="flex items-center justify-center   w-48 h-40 ">
                        {isFeatureImageLoading ? (
                          <div className=" flex justify-center items-center  w-full  h-40 p-1 md:p-2">
                            <FadeLoader
                              className=" w-full h-full "
                              color="#36d7b7"
                            />
                          </div>
                        ) : (
                          <label
                            for="dropzone-file"
                            class="flex flex-col items-center justify-center border-2 border-gray-300  rounded-lg cursor-pointer bg-gray-50   "
                          >
                            <>
                              <div class="flex flex-col items-center justify-center pt-5 pb-6 px-2">
                                <svg
                                  class="w-8 h-8 mb-4 text-gray-500 dark::text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 16"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                  />
                                </svg>
                                <p class="mb-2 text-sm text-gray-500 dark::text-gray-400">
                                  <span class="font-semibold">Upload </span>{" "}
                                  Feature image
                                </p>
                                <p class="text-xs text-gray-500 dark::text-gray-400">
                                  {" "}
                                  PNG or JPG{" "}
                                </p>
                              </div>
                              <input
                                onChange={(e) => handleProdctImage(e, true)}
                                id="dropzone-file"
                                type="file"
                                class="hidden"
                              />
                            </>
                          </label>
                        )}
                      </div>
                    )}
                  </>
                )}
                {/* feature Image Sectionend  here */}

                {/* Product Image loop start from here  */}
                {!products?.has_variant &&
                  productImage?.map((item, index) => (
                    <>
                      {!item?.is_featured && (
                        <div class=" group  h-40  p-1 md:p-2 relative ">
                          <img
                            alt="gallery"
                            class="block object-cover object-center w-full h-full rounded-lg"
                            src={item?.image}
                          />
                          <button
                            onClick={(e) =>
                              handleProductFeatureImageDelete(e, item, false)
                            }
                            className=" hidden group-hover:block hover:bg-white bg-red-400 hover:text-red-600 text-white absolute top-2 right-2 rounded-full  "
                          >
                            <RxCross2 size={30} className=" p-2" />
                          </button>
                        </div>
                      )}
                    </>
                  ))}

                <div class="flex items-center   h-40">
                  {isProductImageLoading ? (
                    <div className=" flex justify-center items-center  w-full  h-40 p-1 md:p-2">
                      <FadeLoader className=" w-full h-full " color="#36d7b7" />
                    </div>
                  ) : (
                    <label
                      for="dropzone-file"
                      class="flex flex-col items-center justify-center border-2 border-gray-300  rounded-lg cursor-pointer bg-gray-50 dark::hover:bg-bray-800 dark::bg-gray-700 hover:bg-gray-100 dark::border-gray-600 dark::hover:border-gray-500 dark::hover:bg-gray-600"
                    >
                      <div class="flex flex-col items-center justify-center pt-5 pb-6 px-2">
                        <BsPlusCircleDotted size={30} />
                        <p class="mb-2 text-sm text-gray-500 dark::text-gray-400 mt-2">
                          <span class="font-semibold">Upload </span> Product
                          image
                        </p>
                        <p class="text-xs text-gray-500 dark::text-gray-400">
                          {" "}
                          PNG or JPG{" "}
                        </p>
                      </div>
                      <input
                        onChange={(e) => handleProdctImage(e, false)}
                        id="dropzone-file"
                        type="file"
                        class="hidden"
                      />
                    </label>
                  )}
                </div>
                {/* Product Image loop end here  */}
              </div>
            </div>
          )}

        {/* pusrchase and Sell input End */}
        {/* varient  start */}
        {products?.has_variant && (
          <div className=" mt-8">
            <h1 className=" text-lg font-bold mb-2">Varients</h1>
            <VariantSection
              isReload={isReload}
              setIsReload={setIsReload}
              setProducts={setProducts}
              products={products}
            />
          </div>
        )}

        {/* product image upload  */}
        {/* {!products?.has_variant && (
          <div className=" my-2  flex items-center flex-wrap ">
            {productImage?.map((item, index) => (
              <div class=" w-1/4 h-40 p-1 md:p-2 relative">
                <img
                  alt="gallery"
                  class="block object-cover object-center w-full h-full rounded-lg"
                  src={item?.image}
                />
                <button
                  onClick={(e) =>
                    handleProductFeatureImageDelete(e, item, false)
                  }
                  className=" hover:bg-white bg-red-400 hover:text-red-600 text-white absolute top-2 right-2 rounded-full  "
                >
                  <RxCross2 size={30} className=" p-2" />
                </button>
              </div>
            ))}

            <div class="flex items-center justify-center w-1/4 h-40">
              {isProductImageLoading ? (
                <h1>Uploading...</h1>
              ) : (
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center border-2 border-gray-300  rounded-lg cursor-pointer bg-gray-50 dark::hover:bg-bray-800 dark::bg-gray-700 hover:bg-gray-100 dark::border-gray-600 dark::hover:border-gray-500 dark::hover:bg-gray-600"
                >
                  <div class="flex flex-col items-center justify-center pt-5 pb-6 px-2">
                    <BsPlusCircleDotted size={30} />
                    <p class="mb-2 text-sm text-gray-500 dark::text-gray-400 mt-2">
                      <span class="font-semibold">Upload </span> Variant image
                    </p>
                    <p class="text-xs text-gray-500 dark::text-gray-400">
                      {" "}
                      PNG or JPG{" "}
                    </p>
                  </div>
                  <input
                    onChange={(e) => handleProdctImage(e, false)}
                    id="dropzone-file"
                    type="file"
                    class="hidden"
                  />
                </label>
              )}
            </div>
          </div>
        )} */}
        <></>
      </div>
    </div>
  );
};

export default ViewProduct;
