import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import DeleteAlert from "../Shared/DeleteAlert";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { exportToCSV } from "../../lib/exportCSV";

const InvoiceTable = () => {
  const { invoiceEditId } = useSelector((state) => state.editState);
  const [invoiceData, setinvoiceData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //setting token
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // delete function
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${process.env.REACT_APP_ROOT_URL}/api/invoice/${id}/`,
            { headers }
          );
          // After successful deletion, refresh the data
          getInvoiceList();
          // Swal.fire("Deleted!", "Your item has been deleted.", "success");
        } catch (error) {
          console.error(error);
          Swal.fire(
            "Error!",
            "An error occurred while deleting the item.",
            "error"
          );
        }
      }
    });
  };

  // Function to fetch data from the API
  const getInvoiceList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/invoice/?page=${currentPage}&search=${searchText}`,
        { headers }
      );
      setTotalItems(response?.data?.count);
      setinvoiceData(response?.data?.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getInvoiceList();
  }, [currentPage, searchText]);

  // ===== ================= csv download ======================
  const downloadCSV = async () => {
    try {
      const response = await fetch(
        "https://api.amamne.com.sg/api/export-data-csv/",
        {
          headers: {
            Accept: "text/csv", // Requesting CSV format
          },
        }
      );

      // // Check if the response is ok (status 200)
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      console.log("blog response: ", blob);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoiceReport.csv");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the CSV file:", error);
    }
  };

  //   console.log("invoiceData", invoiceData);
  return (
    <div className=" m-5  ">
      <div className=" flex justify-between">
        <h1 className=" text-2xl font-bold  text-gray-950 ">Invoice List</h1>
        <div className="flex gap-2">
          <button
            onClick={downloadCSV}
            // onClick={() =>
            //   exportToCSV(
            //     invoiceData,
            //     [
            //       "ref",
            //       "company_name",
            //       "date",
            //       "grand_total",
            //       "status",
            //       "source",
            //       "paid_amount",
            //       "cancel_reason",
            //     ],
            //     "Invoice Report"
            //   )
            // }
            className=" bg-gray-900 text-white px-4 py-2 rounded-lg my-2"
          >
            Export CSV
          </button>
          <Link
            to="/addinvoice"
            className=" bg-gray-900 text-white px-4 py-2 rounded-lg my-2"
          >
            Add Invoice
          </Link>
        </div>
      </div>
      <div class="mb-3">
        <input
          onChange={(e) => setSearchText(e?.target?.value)}
          type="search"
          class="relative m-0 h-10 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none    "
          id="exampleSearch"
          placeholder="Type query"
        />
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <table class="w-full text-sm text-left text-gray-500 dark::text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark::bg-gray-700 dark::text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Code
              </th>
              <th scope="col" class="px-6 py-3">
                Date
              </th>
              {/* <th scope="col" class="px-6 py-3">
                Project Name
              </th> */}
              <th scope="col" class="px-6 py-3">
                Company Name
              </th>
              <th scope="col" class="px-6 py-3">
                Source
              </th>
              <th scope="col" class="px-6 py-3">
                Grand Total
              </th>
              <th scope="col" class="px-6 py-3">
                Sub Total
              </th>

              <th scope="col" class="px-6 py-3">
                Status
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.map((item) => (
              <tr
                class={`${
                  item.id == invoiceEditId ? "bg-yellow-400" : "bg-white"
                } bg-white border-b dark::bg-gray-800 dark::border-gray-700`}
              >
                <th class="px-6 py-4">
                  <p>{item?.ref}</p>
                </th>
                <td class="px-6 py-4">{item?.date}</td>
                {/* <td class="px-6 py-4">{item?.project_name}</td> */}
                <td class="px-6 py-4">{item?.company_name}</td>
                <td class="px-6 py-4">{item?.source}</td>
                <td class="px-6 py-4">{item?.grand_total?.toFixed(2)}</td>
                <td class="px-6 py-4">{item?.sub_total?.toFixed(2)}</td>
                <td class="px-6 py-4">{item?.status}</td>
                <td className="px-6 py-4  ">
                  <div className="  w-full">
                    {/* <button className=" bg-green-600 text-white px-2 py-1 rounded-full">
                    Add Project
                  </button> */}

                    <button className="mx-1 py-2 bg-yellow-600 text-white px-2  rounded-full">
                      <Link to={`/edit-invoice/${item?.id}?ref=${item?.ref}`}>
                        <BiEdit size={16} />
                      </Link>
                    </button>
                    <button className="mx-1 py-2 bg-black text-white px-2  rounded-full">
                      <Link to={`/view-invoice/${item?.id}`}>
                        <AiOutlineEye size={16} />
                      </Link>
                    </button>

                    <button
                      className=" py-2 bg-red-600 text-white px-2  rounded-full"
                      onClick={() => handleDelete(item?.id)}
                    >
                      <AiFillDelete size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="sticky bottom-0  flex justify-center items-center my-5 bg-white py-4 shadow ">
        <Pagination
          onChange={handlePageChange}
          current={currentPage}
          pageSize={itemsPerPage}
          total={totalItems}
        />
      </div>
    </div>
  );
};

export default InvoiceTable;
