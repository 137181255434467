import React from "react";
import "./print.css";
import { calculateTotalPriceAfterDiscount } from "../../lib/pdfUtils";
import { formatPageNumber } from "../../lib/pdfUtils";
import ParentPdf from "../PdfComp/ParentPdf";

const NewPrintInvoice = ({ items, componentRef, quotaionData }) => {
  const totalItems = quotaionData?.product?.length || 0;
  const itemsPerPage = 20;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //---------------------------table header---------------------
  const tableHeader = [
    { header: "SL", field: "sl" },
    { header: "Item", field: "item" },
    { header: "Size", field: "size" },
    { header: "Unit", field: "unit" },
    { header: "Qty", field: "quantity" },
    { header: "Unit Price(S$)", field: "unit_price" },
    quotaionData?.is_item_discount
      ? {
          header: `Discount${quotaionData?.is_flat_discount ? "" : " (%)"}`,
          field: "discount",
        }
      : null,
    { header: "Amount(S$)", field: "amount" },
  ].filter(Boolean); // Filters out any null or false values

  return (
    <div ref={componentRef} className="print-container h-full w-full">
      {Array(totalPages)
        .fill()
        .map((_, pageIndex) => {
          const startItemIndex = pageIndex * itemsPerPage;
          const endItemIndex = Math.min(
            startItemIndex + itemsPerPage,
            totalItems
          );
          const currentPageItems = items.slice(startItemIndex, endItemIndex);
          const pageNumberDisplay = formatPageNumber(pageIndex + 1, totalPages);

          //---------------------------table data---------------------
          const formattedData =
            currentPageItems.length &&
            currentPageItems.map((item, index) => {
              const baseItem = {
                ...item,
                sl: <div className="text-center">{index + 1}</div>,
                item: (
                  <div
                    className="w-full flex flex-wrap whitespace-normal break-words overflow-hidden"
                    style={{ minWidth: "150px", maxWidth: "250px" }}
                  >
                    {item?.category_name === "Ducting" && (
                      <span className="space-x-1">
                        <span className="ml-1">
                          {item?.ducting_type?.product_type}
                        </span>
                        <span>{item?.ducting_type?.thickness}</span>
                        <span>{item?.ducting_type?.brand_type}</span>
                        <span className="uppercase">
                          {item?.ducting_type?.joint_type}
                        </span>
                      </span>
                    )}
                    <span
                      className={`${
                        item?.category_name === "Ducting" ? "ml-1" : ""
                      }`}
                    >
                      {item?.parent
                        ? item?.parent_name
                        : item?.name || "\u00A0"}
                    </span>
                  </div>
                ),
                size: (
                  <div
                    className="text-xs flex items-center whitespace-normal break-words overflow-hidden"
                    style={{ minWidth: "100px", maxWidth: "200px" }}
                  >
                    <h1 className="line-clamp-2">
                      {item?.category_name === "Ducting"
                        ? item?.display_dimensions
                        : item?.parent
                        ? Object.values(item?.variant || {}).join("/ ")
                        : item?.description || "\u00A0"}
                    </h1>
                  </div>
                ),
                unit: (
                  <div className="text-center w-full min-w-[50px] max-w-[100px]">
                    {item?.unit || "\u00A0"}
                  </div>
                ),
                quantity: (
                  <div className="text-center w-full min-w-[50px] max-w-[100px]">
                    {item?.new_quantity || "\u00A0"}
                  </div>
                ),
                unit_price: (
                  <div className="text-right pr-3 w-full min-w-[50px] max-w-[100px]">
                    {item
                      ? `${
                          item.category_name === "Ducting"
                            ? Number(item.unit_price)?.toFixed(3)
                            : Number(item.sell_price)?.toFixed(3)
                        }`
                      : "\u00A0"}
                  </div>
                ),
                amount: (
                  <div className="text-right w-full min-w-[50px] max-w-[100px]">
                    {item
                      ? `$${calculateTotalPriceAfterDiscount(
                          quotaionData?.is_item_discount,
                          item
                        )}`
                      : "\u00A0"}
                  </div>
                ),
              };

              // Add the discount field conditionally
              if (quotaionData?.is_item_discount) {
                baseItem.discount = (
                  <div className="text-center w-full min-w-[50px] max-w-[100px]">
                    {item && Number(item?.item_discount) > 0
                      ? `${Number(item.item_discount).toFixed(2)}${
                          quotaionData?.is_flat_discount ? "" : " %"
                        }`
                      : "\u00A0"}
                  </div>
                );
              }
              return baseItem;
            });

          return (
            <div key={pageIndex} className="page-content">
              <ParentPdf
                data={quotaionData}
                pageNumber={pageNumberDisplay}
                tableData={formattedData}
                tableHeader={tableHeader}
                title={"INVOICE"}
                footerShow={"invoice"}
                invoiceFooterData={quotaionData}
                acceptedBy={false}
                showBankInfo={true}
                showTermsCondition={false}
                customerInfoFor={"invoice"}
                totalPages={totalPages}
                pageIndex={pageIndex}
              />
            </div>
          );
        })}
    </div>
  );
};

export default NewPrintInvoice;
